<script>
import EmptyState from "@/components/core/EmptyState";

export default {
  name: "NoThreadView",
  components: {
    EmptyState,
  },
};
</script>

<template>
  <EmptyState>no selected message</EmptyState>
</template>
